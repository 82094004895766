import React, {PropsWithChildren, useRef} from "react";
import axios from "axios";
import {apiHost} from "../../../service/api-host";

interface UploadButton {
  onChange?(selectedFile: any, name: string): void
  title: string
  name: string
}

const UploadButton: React.FC<UploadButton> = ({title, name, ...props}) => {

  const uploadRef = useRef<HTMLInputElement>()

  const onChange = async (e: any) => {
    const selectedFile = e.target.files[0]

    if(props.onChange) {
      props.onChange(selectedFile, name)
    }

  }

  return <>
    <div
      className={"mt-4 overflow-hidden sm:overflow-auto"}
    >
    <input  ref={uploadRef as any} type={"file"} onChange={onChange}/>
    </div>
  </>
}

export default UploadButton
