import React from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Home from "./views/home";
import Upload from "./views/upload";
import FormPage from "./components/Layout/FormPage";
import ScrollToTop from "./components/ScrollToTop";
import LandingPage from "./components/Layout/LandingPage";
import Landing from "./views/landing";
import PrivacyPolicy from "./views/privacy-policy";
import TermsOfUse from "./views/terms-of-use";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Awardees from "./views/awardees";

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Helmet>
          {process.env["REACT_APP_DOMAIN_SCRIPT_ID"] && (
            <script
              src={"https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"}
              type={"text/javascript"}
              charSet={"UTF-8"}
              data-domain-script={process.env["REACT_APP_DOMAIN_SCRIPT_ID"]}
            ></script>
          )}
        </Helmet>
        <ScrollToTop />
        <Routes>
          <Route
            element={
              <LandingPage>
                <Outlet />
              </LandingPage>
            }
          >
            {/* <Route path={"/"} element={<Landing />} /> */}
            {/*<Route path={"/contact"}/>*/}
            <Route path={"/awardees"} element={<Awardees />} />
            <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
            <Route path={"/terms-of-use"} element={<TermsOfUse />} />
          </Route>
          <Route
            element={
              <FormPage>
                <Outlet />
              </FormPage>
            }
          >
            <Route path={"/"} element={<Home />} />
            <Route path={"/apply"} element={<Upload />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
