import React from "react";
import UploadForm from "./Components/UploadForm";

const Upload: React.FC = () => {
  return (
    <>
      <div className={"bg-white text-black py-8"}>
        <div className={"px-3 sm:px-4 md:px-4 md:w-full lg:w-4/5 mx-auto p-0"}>
          <div className={"md:flex md:flex-row md:gap-x-4 lg:gap-x-8"}>
            <div className={"md:flex-auto md:w-2/3"}>
              <h2
                className={"font-medium font-serif text-midnight text-5xl mb-4"}
              >
                Want to apply?
              </h2>
              <h3 className={"font-bold text-2xl text-flame"}>
                We will be accepting applications from May 1st 2023.
              </h3>
              <h2 className={"mt-8 mb-4 text-xl font-bold"}>
                Applications are invited from healthcare professionals, experts
                and advocates from rare cholestatic liver diseases, PFIC and
                ALGS communities covering both pediatric and adult patient
                populations.
              </h2>
              <h2 className={"mt-8 mb-4 text-xl font-bold"}>
                Applications should cover sustainable, innovative, replicable
                projects which improve the standard of care of PFIC and/or ALGS
                and optimize the care pathway.
              </h2>
              <div
                className={
                  "rounded-md bg-albireo-blue-pale p-2 sm:p-4 border-t-8 border-b-2 border-albireo-blue mt-8"
                }
              >
                <h2 className={"text-2xl text-albireo-blue font-bold"}>
                  Apply for a grant
                </h2>
                <p className={"text-lg font-normal mt-4"}>
                  To make the process as easy as possible, we've created a
                  downloadable form that you can fill in offline.
                </p>
                <p className={"text-lg font-normal mt-4"}>
                  <a
                    href={"/doc/SPARK_2023_Grant_application_form.pdf"}
                    target={"_blank"}
                    className={
                      "text-2xl font-bold text-albireo-blue underline underline-offset-8"
                    }
                  >
                    <i className={"fa-solid fa-file-arrow-down mr-4"}></i>
                    Download the application form
                  </a>
                </p>
                <h2 className={"text-2xl font-bold mt-12 mb-4"}>
                  Please upload your application form and supporting letter
                  here.
                </h2>

                <UploadForm onComplete={() => {}} />
              </div>
              <div className={"mt-8"}>
                <h2 className={"text-2xl font-bold text-midnight mb-4"}>
                  The application process
                </h2>
                <p className={"font-normal text-lg mt-4"}>
                  The deadline for 2023 grant applications is{" "}
                  <span className={"font-bold"}>Sunday 15th October</span>.
                </p>
                <p className={"font-normal text-lg mt-4"}>
                  The program will provide financial support for short-term
                  initiatives that are funded for up to 1 year. This will be up
                  to €25,000.
                </p>
                <p className={"font-normal text-lg mt-4"}>
                  All applicants are requested to provide a supporting letter
                  from their organisation with their application.
                </p>
                <p className={"font-normal text-lg mt-4"}>
                  Due diligence will be carried out on all submitted
                  applications. Applicants may be contacted to supply additional
                  information.
                </p>
                <p className={"font-normal text-lg mt-4"}>
                  All applicants will be informed of the outcome of their applications in December 2023, with funding to start in early 2024.
                </p>
                <p className={"text-lg mt-4 font-bold"}>
                  This program is open to applicants worldwide.
                </p>
              </div>
            </div>
            <div
              className={
                "mt-8 md:mt-0 md:flex-auto md:w-1/3 md:border-l-2 md:pl-4 lg:pl-8 border-storm-cloud"
              }
            >
              <div
                className={
                  "rounded-md border-green-apple border bg-green-apple-pale p-2 md:p-4 lg:p-8"
                }
              >
                <h2 className={"text-xl font-bold text-green-apple-dark"}>
                  <i className={"fa-solid fa-circle-check mr-2"}></i>
                  To be considered, your project must:
                </h2>
                <ul className={"text-base mt-4 ml-6"}>
                  <li className={"list-disc"}>
                    Be completed within 12 months of award{" "}
                    <span className={"italic"}>
                      (pilot projects for multi-year initiatives will be
                      considered on a case-by-case basis)
                    </span>
                  </li>
                  <li className={"list-disc"}>
                    Be replicable, scalable and relevant to other countries
                  </li>
                  <li className={"list-disc"}>
                    Have measurable (and measured) outcomes
                  </li>
                  <li className={"list-disc"}>Be innovative and impactful</li>
                  <li className={"list-disc"}>
                    Applications with defined publication plans are desirable
                  </li>
                </ul>
              </div>
              <div
                className={
                  "rounded-md border border-crimson bg-crimson-pale mt-8 p-2 md:p-4 lg:p-8"
                }
              >
                <h2 className={"text-xl font-bold text-crimson-dark"}>
                  <i className={"fa-solid fa-circle-xmark mr-2"}></i>
                  We are unable to fund under SPARK:
                </h2>
                <ul className={"text-base mt-4 ml-6"}>
                  <li className={"list-disc"}>Clinical trials</li>
                  <li className={"list-disc"}>
                    Phase IV trials or investigator-sponsored studies*
                  </li>
                  <li className={"list-disc"}>PhD support</li>
                  <li className={"list-disc"}>
                    Overheads/operational expenses, e.g. headcount, equipment or
                    utilities (for patient groups, these expenses are often
                    referred to as core funding)
                  </li>
                  <li className={"list-disc"}>
                    Costs of setting up a new charity
                  </li>
                  <li className={"list-disc"}>
                    Purchase of genetic testing or medicines
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={
              "rounded-md bg-storm-cloud-pale p-8 border-t-8 border-b-2 border-storm-cloud-light mt-8"
            }
          >
            <h2 className={"text-2xl text-albireo-blue font-bold"}>
              Get in touch
            </h2>
            <p className={"text-lg font-normal mt-4 mb-4"}>
              If you need more information, we’re here to help. <br />
              Please send us your question(s) and we will arrange a follow-up
              call.
            </p>
            <a
              href={"mailto:spark@albireopharma.com"}
              className={
                "w-full md:w-auto border-b-4 border-midnight text-xl font-bold text-white rounded-md bg-albireo-blue px-4 py-2"
              }
            >
              <i className={"fa-solid fa-envelope mr-4"}></i>
              Send us an email
            </a>
          </div>
          <p className={"font-normal text-sm mt-5"}>
            * Investigator-sponsored studies (ISS) are defined as clinical
            research in which the investigator conceives the research, develops
            the protocol, and serves as sponsor investigator. ISS can also be
            known as investigator sponsored research (ISR), expert-initiated
            research (EIR) or any other term which may reference
            investigator-sponsored or investigator-initiated research.
          </p>
        </div>
      </div>
    </>
  );
};

export default Upload;
