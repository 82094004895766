import React from "react";
import pin from "../../views/img/pin.png";
import bg from "../../views/img/awardees-bg.png";
import { data } from "./consts";

const Awardees: React.FC = () => (
  <>
    <div className="lg:relative">
      <img src={bg} alt="backgroud" className="hidden lg:block" />
      <h2
        className={
          "text-3xl md:text-4xl xl:text-5xl font-bold text-albireo-blue lg:absolute top-12 w-full text-center my-8 lg:my-0"
        }
      >
        SPARK grants program: 2022 grant awardees!
      </h2>
    </div>
    <div className="w-4/5 py-8 pt-0 pb-24 mx-auto text-black bg-white article-body">
      <div className="grid grid-cols-1 mr-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-10 gap-x-10 md:mr-6">
        {data.map(({ id, image, name, location, project, desc }) => (
          <div key={id} className="flex-col">
            <div className="relative flex items-center w-full">
              <img
                className="z-30 w-auto h-32 mx-auto translate-y-3"
                src={pin}
                alt="pin"
              />
              {image && (
                <div className="absolute z-30 w-24 h-24 translate-x-[-50%] left-1/2 rounded-full bg-white overflow-hidden flex justify-center items-center">
                  <img className="" src={image} alt="doctor" />
                </div>
              )}
            </div>
            <div className="relative p-5 pt-5 h-3/4 bg-flame-300">
              <div className="hidden md:block absolute right-[-40px] top-0 z-10 w-10 h-full bg-flame-300" />
              <h3 className="font-bold text-[20px] text-center">{location}</h3>
              <p className="text-center text-[12px] mb-3">{name}</p>
              <h6 className="font-bold text-center text-albireo-blue text-[14px] min-h-[100px]">
                {project}
              </h6>
              <p className="text-center text-[14px]">{desc}</p>
            </div>
          </div>
        ))}
      </div>

      <p className="mt-8">
        CLD - cholestatic liver disease; ICP - intrahepatic cholestasis in
        pregnancy; PFIC - progressive familial intrahepatic cholestasis; SBA -
        serum bile acid; QoL - quality of life.
      </p>
    </div>
  </>
);

export default Awardees;
