import React from "react";

const TermsOfUse: React.FC = () => <div className="w-4/5 mx-auto bg-white text-black py-8 article-body">
  <h1 className="text-3xl mb-8 font-bold">Terms of use</h1>

  <p className="text-small text-gray-900 mb-6">Last updated January 4, 2018.</p>
  <p className="mb-6">The website <a href="https://albireopharma.com/" className="text-albireo-blue underline" target="_blank">https://albireopharma.com/</a> (“Site”) is provided by Albireo Pharma, Inc. (“Albireo,”
    “we,” “us” or “our”) to provide information and selected functionality to parties interested in Albireo or its
    affiliates (“User,” “Users” “you” or “your”).</p>
  <p className="mb-6">PLEASE READ THESE TERMS OF SERVICE AND OUR PRIVACY POLICY (COLLECTIVELY, THE “AGREEMENT”)
    CAREFULLY BECAUSE THIS AGREEMENT CONSTITUTES A LEGALLY BINDING CONTRACT BETWEEN YOU AND ALBIREO. IF YOU DO NOT WISH
    TO ACCEPT THIS AGREEMENT, PLEASE DO NOT ACCESS OR USE THE SITE. BY ACCESSING, DOWNLOADING, USING, THE SITE, YOU
    AGREE (1) TO BE BOUND BY THIS AGREEMENT, (2) YOU ARE AT LEAST 18 YEARS OLD, (3) YOU HAVE READ AND UNDERSTAND THIS
    AGREEMENT AND (4) YOU ACCEPT THIS AGREEMENT.</p>
  <ol className="mb-6">
    <li className="mb-6">
      <h2 className="inline-block text-xl mb-2">MODIFICATION OF THIS AGREEMENT.</h2>
      <p className="mb-6">We may change, modify, add or remove portions of the Agreement (each, an “Update”) at any time
        and in our sole discretion without prior notice and each such Update will be effective immediately. If we make
        an Update, we will change the “Last Updated” date above. Your continued use of the Site will confirm your
        acceptance of the Update. We encourage you to frequently review this Agreement to ensure you understand the
        latest terms and conditions associated with use of the Site. If you do not agree to an Update, you must
        discontinue using the Site.</p></li>
    <li><h2 className="inline-block text-xl mb-2">OWNERSHIP; PROPRIETARY RIGHTS.</h2>
      <p className="mb-6">The Site, including all information and materials contained in the Site, is owned and operated
        by Albireo or, to the extent expressly otherwise indicated in the Site, other parties. All content, images,
        illustrations, designs, names, products, services, icons, photographs, video clips, typefaces, source and object
        code, format, queries, algorithms, visual interfaces, HTML, information, graphics, design, look and feel,
        compilation and all other elements (whether written or otherwise) of the Site (collectively, the “Site
        Materials”), as well as their selection and arrangement, and all intellectual property and other rights relating
        to Site Materials, are protected by, as appropriate by United States copyright, trade dress, patent, and
        trademark laws, international laws and conventions, and all other relevant intellectual property and proprietary
        rights, and applicable laws. All Site Materials are the property of Albireo or its subsidiaries or affiliated
        companies and/or third-party licensors. Except as expressly authorized by Albireo under this Agreement, you
        agree not to directly or indirectly sell, license, distribute, copy, modify, publicly perform or display,
        transmit, publish, edit, adapt, create derivative works from, or otherwise make unauthorized use of the Site
        Materials.</p></li>
    <li>
      <h2 className="inline-block text-xl mb-6">GUIDELINES; USAGE RULES; PROHIBITED CONDUCT AND USES.</h2>
      <ol className="ml-6 mb-6">
        <li>YOU UNDERSTAND AND HEREBY ACKNOWLEDGE AND AGREE THAT YOU WILL ABIDE BY THE USAGE RULES SET FORTH IN THIS
          SECTION 3 (THE “GUIDELINES”). IN PARTICULAR, YOU WILL NOT:
          <ol className="ml-6 mb-6">
            <li>use the Site or any information displayed within the Site to stalk, harass, abuse, defame, threaten or
              defraud other Users, or collect, attempt to collect or store location or personal information about other
              Users;
            </li>
            <li>use the Site if you are under the age of eighteen (18) years old;</li>
            <li>use the Site for any illegal purpose, or in violation of any local, state, national, or international
              law, including laws governing intellectual property or other proprietary rights, data security, privacy or
              import or export control;
            </li>
            <li>make unsolicited offers, advertisements, proposals, or send junk mail, to other Users. This includes,
              but is not limited to, unsolicited advertising, promotional materials or other solicitation material, bulk
              mailing of commercial advertising, chain mail, informational announcements, charity requests, and
              petitions for signatures, surveying or requests to participate in surveys or studies;
            </li>
            <li>impersonate any person or entity, falsely claim an affiliation with any person or entity, or access the
              Site accounts of other Users;
            </li>
            <li>misrepresent the source, identity or content of information transmitted via the Site;</li>
            <li>remove, circumvent, disable, damage or otherwise interfere with security-related features of the Site,
              features that prevent or restrict use or copying of any content accessible through the Site, or features
              that enforce limitations on use of the Site;
            </li>
            <li>intentionally interfere with or damage operation of the Site or any User’s enjoyment of them, by any
              means, including uploading or otherwise disseminating viruses, worms, invalid data, keyloggers, spyware,
              Trojan horses, time bombs, or other malicious or harmful code, or imposing an unreasonable or
              disproportionately large load on our infrastructure;
            </li>
            <li>post, store, send, transmit, or disseminate any information or material that a reasonable person could
              deem to be objectionable, defamatory, libelous, offensive, obscene, indecent, pornographic, harassing,
              threatening, embarrassing, distressing, vulgar, hateful, racially or ethnically or otherwise offensive to
              any group or individual, intentionally misleading, false, harmful to minors, or otherwise inappropriate,
              regardless of whether the information or material, or its dissemination, is unlawful;
            </li>
            <li>post, store, send, transmit, or disseminate any information or material that infringes any patents,
              trademarks, trade secrets, copyrights, or any other proprietary or intellectual property rights;
            </li>
            <li>attempt to gain unauthorized access to the Site, or any part of it, other accounts, computer systems or
              networks connected to the Site, or any part of it, through hacking, password mining or any other means or
              interfere or attempt to interfere with the proper working of the Site or any activities conducted on the
              Site;
            </li>
            <li>hack, spam or phish Albireo or any Users; or</li>
            <li>hold Albireo responsible for your use of the Site.
              <p>Please let us know about any inappropriate behavior that you become aware of within the Site. If you
                find something that violates these Guidelines or the Agreement otherwise, let us know by sending us an
                email to <a href={"mailto:help@albireopharma.com"}
                            className={"text-albireo-blue underline"}>help@albireopharma.com</a>. We reserve the right, in
                our sole and absolute discretion, to deny you or anyone access to the Site without notice.</p>
            </li>
          </ol>
        </li>
      </ol>
    </li>
    <li>
      <h2 className="inline-block text-xl mb-6">USAGE; REFUSAL OR SUSPENSION OF SERVICE.</h2>
      <ol className="ml-6 mb-6">
        <li>ALBIREO RESERVES THE RIGHT, BUT HAS NO OBLIGATION, TO HAVE OUR SYSTEMS MONITOR ANY USER’S USE OF THE SITE.
          ACCORDINGLY, ALBIREO ALSO RESERVES THE RIGHT TO DISABLE ANY USER’S USE OF OR ACCESS TO THE SITE.
        </li>
        <li>You alone are responsible for your involvement with other Users. You agree that Albireo will not be
          responsible for any loss or damage incurred as the result of any such interactions.
        </li>
      </ol>
    </li>
    <li><h2 className="inline-block text-xl mb-6">THIRD-PARTY SITES, PRODUCTS AND SERVICES; LINKS.</h2>
      <p className="mb-6">The Site may include links to other websites or services (“Third Party Websites”) solely as a
        convenience to Users. Albireo does not endorse any Third Party Website or the information, material, products or
        services contained on other sites linked to, or accessible through, any Third Party Website. Furthermore,
        Albireo makes no express or implied warranties with regard to the information, material, products or services
        that are contained on or accessible through any Third Party Website. Access and use of any Third Party Website,
        including information, material, products and services on such Third Party Website or available through such
        Third Party Website is solely at your own risk.</p></li>
    <li>
      <h2 className="inline-block text-xl mb-6">USER RESTRICTIONS</h2>
      <p className="mb-6">You may not: (i) modify, disassemble, decompile or reverse engineer the Site; (ii) rent,
        lease, loan, resell, sublicense, distribute or otherwise transfer the Site to any third party or use the Site to
        provide time sharing or similar services for any third party; (iii) make any copy of the Site; (iv) remove,
        circumvent, disable, damage or otherwise interfere with security-related features of the Site, features that
        prevent or restrict use or copying of any content accessible through the Site, or features that enforce
        limitations on use of the Site; or (v) delete the copyright and any other proprietary rights notice on the
        Site.</p></li>
    <li>
      <h2 className="inline-block text-xl mb-6">VIOLATIONS; TERMINATION.</h2>
      <p className="mb-6">You agree that Albireo, in the good faith belief that you have violated any of the terms and
        conditions of the Agreement, may terminate your use of the Site or any portion thereof at any time. You agree
        that any termination of your access to the Site may have or portion thereof may be effected without prior notice
        and you agree that Albireo will not be liable to you or any third party for any such termination. Albireo does
        not permit copyright infringing activities on the Site and reserves the rights to terminate access to the Site
        and to remove all content submitted by any persons who are found to be infringers. Any suspected fraudulent,
        abusive or illegal activity that may be grounds for termination of your use of the Site may be referred to
        appropriate law enforcement authorities. These remedies are in addition to any other remedies Albireo may have
        at law or in equity.</p></li>
    <li><h2 className="inline-block text-xl mb-6">DISCLAIMERS; NO WARRANTIES.</h2>
      <ol className="ml-6 mb-6">
        <li>THE SITE IS MADE AVAILABLE “AS IS,” “AS AVAILABLE” AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR
          IMPLIED. ALBIREO, AND ITS SUPPLIERS, LICENSORS AND PARTNERS, DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
          INCLUDING IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT
          OF PROPRIETARY RIGHTS.
        </li>
        <li>ALBIREO AND ITS SUPPLIERS, LICENSORS AND BUSINESS PARTNERS DO NOT WARRANT THAT THE FUNCTIONS CONTAINED IN
          THE SITE WILL BE UNINTERRUPTED OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE OR THE SERVER
          THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
        </li>
        <li>CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES. IF THESE LAWS APPLY TO YOU, SOME OR ALL
          OF THE ABOVE DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL
          RIGHTS.
        </li>
      </ol>
    </li>
    <li><h2 className="inline-block text-xl mb-6">INDEMNIFICATION; HOLD HARMLESS.</h2>
      <p className="mb-6">You agree to indemnify, defend, and hold Albireo (and its affiliated companies and its and
        their respective contractors, employees, directors, officers, agents, suppliers, licensors and partners)
        harmless from any and all claims, suits, actions, losses, costs, damages, and any other liabilities, including
        attorneys’ fees, brought by a third party arising out of or related to: (i) your use or misuse of the Site
        generally; (ii) any violation of the rights of any other person or entity by you; (iii) any breach or violation
        by you of the Guidelines or the Agreement otherwise. Albireo reserves the right, at your expense, to assume the
        exclusive defense and control of any matter for which you are required to indemnify us, and you agree to
        cooperate with our defense of these claims.</p>
    </li>
    <li><h2 className="inline-block text-xl mb-6">LIMITATION OF LIABILITY AND DAMAGES.</h2>
      <ol className="ml-6 mb-6">
        <li>YOU ACKNOWLEDGE AND AGREE THAT UNDER NO CIRCUMSTANCES, INCLUDING NEGLIGENCE, WILL ALBIREO (OR ITS AFFILIATED
          COMPANIES OR ANY OF ITS OR THEIR RESPECTIVE CONTRACTORS, EMPLOYEES, DIRECTORS, OFFICERS, AGENTS, SUPPLIERS,
          LICENSORS OR BUSINESS PARTNERS) BE LIABLE TO YOU FOR ANY SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, RELIANCE,
          CONSEQUENTIAL OR EXEMPLARY DAMAGES, EVEN IF ALBIREO OR AN ALBIREO AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED
          OF THE POSSIBILITY OF SUCH DAMAGES. YOU AGREE THAT THE LIMITATIONS OF LIABILITY SET FORTH IN THIS SECTION 10
          WILL SURVIVE ANY TERMINATION OR EXPIRATION OF THE AGREEMENT AND WILL APPLY EVEN IF ANY LIMITED REMEDY
          SPECIFIED HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
        </li>
        <li>IN NO EVENT SHALL THE TOTAL LIABILITY TO YOU OF ALBIREO (AND ITS AFFILIATED COMPANIES AND ITS AND THEIR
          RESPECTIVE CONTRACTORS, EMPLOYEES, DIRECTORS, OFFICERS, AGENTS, SUPPLIERS, LICENSORS AND BUSINESS PARTNERS,
          COLLECTIVELY) FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE),
          WARRANTY OR OTHERWISE) EXCEED FIFTY U.S. DOLLARS.
        </li>
        <li>APPLICABLE LAW IN YOUR STATE MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR
          CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES, YOU AGREE
          THAT ALBIREO’S LIABILITY WILL BE LIMITED TO THE EXTENT PERMITTED BY APPLICABLE LAW IN YOUR STATE.
        </li>
      </ol>
    </li>
    <li><h2 className="inline-block text-xl mb-6">BENEFIT OF THE BARGAIN.</h2>
      <p className="mb-6">YOU ACKNOWLEDGE AND AGREE THAT ALBIREO HAS OFFERED THE SITE AND ENTERED INTO THE AGREEMENT IN
        RELIANCE UPON THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH ABOVE. YOU FURTHER ACKNOWLEDGE
        AND AGREE THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH IN THIS AGREEMENT REFLECT A
        REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU AND ALBIREO AND THAT THE WARRANTY DISCLAIMERS AND THE
        LIMITATIONS OF LIABILITY SET FORTH IN THE AGREEMENT FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN YOU AND
        ALBIREO. ALBIREO WOULD NOT BE ABLE TO PROVIDE THE SITE TO YOU ON AN ECONOMICALLY REASONABLE BASIS WITHOUT THESE
        LIMITATIONS AND DISCLAIMERS.</p>
    </li>
    <li><h2 className="inline-block text-xl mb-6">RELEASE.</h2>
      <p className="mb-6">You hereby release and forever discharge Albireo (and its affiliated companies and its and
        their respective directors, officers, employees, agents, successors and assigns) from, and hereby waive and
        relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation,
        liability, action and cause of action of every kind and nature (including personal injuries, death, and property
        damage), that has arisen or arises directly or indirectly out of, or relates directly or indirectly to: (i) any
        interactions with, or act or omission of the Site; or (ii) any third party site, product, service or link
        included on or accessed through the Site. IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL
        CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS
        WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE,
        WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”</p>
    </li>
    <li>
      <h2 className="inline-block text-xl mb-6">MISCELLANEOUS.</h2>
      <ol className="ml-6 mb-6">
        <li>Governing Law. This Agreement shall be governed by and construed in accordance with the laws of the
          Commonwealth of Massachusetts, without giving effect to any principles of conflicts of law.
        </li>
        <li>Jurisdiction. Except as provided in, and subject to, Section 13.3, you agree that any action at law or in
          equity arising out of or relating to this Agreement or the Site shall be filed only in the state or federal
          courts located in Boston, Massachusetts and you hereby consent and submit exclusively to the personal
          jurisdiction of such courts for the purposes of litigating any such action. Nothing in this Agreement shall
          prevent Albireo from seeking injunctive relief in a court of competent jurisdiction.
        </li>
        <li><h3 className="inline-block text-l">Arbitration.</h3>
          <ol className="ml-6">
            <li>If a dispute arises between you and Albireo, our goal is to learn about and address your concerns and,
              if we are unable to do so to your satisfaction, to provide you with a neutral and cost effective means of
              resolving the dispute quickly. You agree that you will notify us about any dispute you have with Albireo
              regarding the Site by emailing help@albireopharma.com, Subject: Arbitration.
            </li>
            <li>You and Albireo agree that we will resolve any claim or controversy at law or equity that arises out of
              or relates to this Agreement or the Site (a “claim”) in accordance with this Section 13. Notwithstanding
              anything to the contrary, for any claim where the total amount of the award sought is less than $10,000
              USD, the party requesting relief may choose to resolve the dispute through binding non-appearance-based
              arbitration in accordance with the following: (a) the arbitration will be provided through a
              nationally-recognized alternative dispute resolution provider mutually agreed upon by the parties; (b) the
              arbitration will be conducted in one or more of the following manners at the option of the party
              initiating arbitration: telephone, online, or written submissions; (c) the arbitration will not involve
              any personal appearances by the parties or witnesses unless otherwise agreed by the parties; and (d) any
              judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.
            </li>
            <li>All claims between you and Albireo must be resolved in accordance with this Section 13. All claims filed
              or brought contrary to this Section 13 shall be considered improperly filed.
            </li>
          </ol>
        </li>
        <li>Severability, Waiver. If any provision of this Agreement shall be unlawful, void, or for any reason
          unenforceable, then that provision shall be deemed severable from this Agreement and shall not affect the
          validity and enforceability of any remaining provisions. A provision of this Agreement may be waived only by a
          written instrument executed by the party entitled to the benefit of such provision. The failure of any party
          at any time to require performance of any provision of this Agreement shall in no manner affect such party’s
          right at a later time to enforce the same. A waiver of any breach of any provision of this Agreement shall not
          be construed as a continuing waiver of other breaches of the same or other provisions of this Agreement.
        </li>
        <li>Notices. Albireo may provide you with notices, including those regarding changes to this Agreement, by
          email, regular mail or postings on the Site. You must provide notice to Albireo by email or regular mail using
          the information in Section 14.
        </li>
        <li>Assignment. This Agreement (including the Guidelines), and any rights and licenses granted hereunder, may
          not be transferred or assigned by you. Albireo may at any time, for any reason and without restriction,
          transfer or assign this Agreement and the obligations contained in this Agreement to a third party. You hereby
          acknowledge and agree that if another company acquires Albireo or substantially all of its assets (by sale,
          merger, or otherwise), that transaction may include a sale or transfer of your Personal Information as defined
          by our privacy policy (available here) and you agree to such transfer without further action or confirmation.
        </li>
        <li>Survival. Any and all provisions related to or regarding limitation of liability, disclaimers, and
          indemnification, as well as any other provisions which by their nature are intended to survive expiration or
          termination of this Agreement, do and hereby survive any expiration or termination of this Agreement or any
          termination of your use of access to the Site.
        </li>
        <li>Headings; Construction; Entire Agreement. The heading references herein are for convenience purposes only,
          do not constitute a part of this Agreement, and shall not be deemed to limit or affect any of the provisions
          hereof. The word “including” means “including without limitation.” This is the entire agreement between us
          relating to the subject matter herein and shall not be modified except in writing, signed by both you and
          Albireo, or by a change to the Agreement.
        </li>
        <li>Claims. YOU AND ALBIREO AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SITE MUST COMMENCE
          WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
        </li>
      </ol>
    </li>
    <li>
      <h2 className="inline-block text-xl mb-6">CONTACT INFORMATION.</h2>
      <p className="mb-6">
        Albireo welcomes your comments or questions regarding these Terms of Service. Please contact us by using the
        following information:
        Mailing Address: Albireo Pharma, Inc., 53 State Street, 19th Floor, Boston, Massachusetts 02109 (USA)
      </p>
      <p className="mb-6">Email: <a href="mailto:help@albireopharma.com">help@albireopharma.com</a></p>
    </li>
  </ol>

</div>

export default TermsOfUse
