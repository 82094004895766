import React, { PropsWithChildren } from "react";
import logo from "../../views/img/logo.png";
import sparkp from "../../views/img/SPARK_Programme.png";
import { Link } from "react-router-dom";

const LandingPage: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <header>
        <div className={"w-4/5 mx-auto my-4 md:grid md:grid-cols-2"}>
          <div>
            <a href={"./"}>
              <img className={"h-16"} src={logo} alt={"Albireo logo"} />
            </a>
            <div className={"text-3xl font-bold font-sans text-blue-900"}>
              <span className={"text-flame"}>Grants in Rare Liver Disease</span>
            </div>
          </div>
          <div
            className={
              "mb-0 mt-4 md:mt-0 my-4 inline-flex flex-col items-end place-self-end"
            }
          >
            <img className={"h-32 w-auto"} src={sparkp} alt={"spark logo"} />
          </div>
        </div>
      </header>
      <div className={"flex flex-col"}>
        <div
          className={
            "py-8 w-full text-white bg-gradient-to-br from-midnight to-albireo-blue"
          }
        >
          <div className={"w-4/5 mx-auto text-3xl text-center"}>
            <h2 className={"text-3xl font-bold"}>
              <span className={"text-flame"}>SPARK</span> is an exciting program
              established by Albireo
            </h2>
            <p className={"text-2xl"}>
              to identify and drive forward improvements in the quality of care
              for rare liver diseases.
            </p>
          </div>
        </div>
      </div>
      {children}
      <div
        className={
          "py-8 w-full text-white bg-gradient-to-br from-midnight-dark to-midnight"
        }
      >
        <div className={"w-4/5 mx-auto text-center"}>
          <div className={"grid grid-cols-4"}>
            <div className={"text-white"}>
              <Link to={"/"} className={"underline"}>
                About SPARK and How to Apply
              </Link>
            </div>
            <div className={"text-white"}>
              <Link to={"/awardees"} className={"underline"}>
                2022 Grant Awardees
              </Link>
            </div>
            <div className={"text-white"}>
              <Link to={"/privacy-policy"} className={"underline"}>
                Privacy Policy
              </Link>
            </div>
            <div className={"text-white"}>
              <Link to={"/terms-of-use"} className={"underline"}>
                Terms of Use
              </Link>
            </div>
            {/*<div className={"text-white"}>Cookies Preferences</div>*/}
          </div>
        </div>
      </div>
      <div className={"py-8 w-full"}>
        <div className={"w-4/5 mx-auto p-0"}>
          <p className={"text-sm text-gray-500 text-right"}>
            &copy; 2023 Albireo Pharma, Inc.&nbsp;|&nbsp;Job bag no:
            GL-NP-23-00003 &nbsp;|&nbsp;Date of preparation: May 2023
          </p>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
