import RaffaeleLorioPhoto from "../../views/img/Prof_Raffaele_Lorio.jpg";
import AndreaPietrobattistaPhoto from "../../views/img/Andrea_Pietrobattista_MD.jpg";
import NolwennLabordePhoto from "../../views/img/Dr_Nolwenn_Laborde.png";
import JoseWillemsePhoto from "../../views/img/Jose_Willemse_MSc_.png";
import ToniIllhardtPhoto from "../../views/img/Dr_Toni_Illhardt.jpg";
import AngeloDiGiorgioPhoto from "../../views/img/Dr_Angelo_di_Giorgio.jpg";
import FilomenaMoriscoPhoto from "../../views/img/Prof_Filomena_Morisco.jpg";
import GiovanniVitalePhoto from "../../views/img/Giovanni_Vitale_MD.png";

export const data = [
  {
    id: 0,
    image: RaffaeleLorioPhoto,
    name: "Prof. Raffaele Lorio",
    location: "University of Naples “Frederico II”, Italy",
    project:
      "Intrahepatic cholestasis in pregnancy (ICP): Bringing out the submerged",
    desc: "To characterize women with ICP exhibiting a progressive familial intrahepatic cholestasis (PFIC) phenotype and to identify which of their children are at risk of cholestasis. The goal is to identify candidates for ileal bile acid transporter treatment.",
  },
  {
    id: 1,
    image: AndreaPietrobattistaPhoto,
    name: "Andrea Pietrobattista, MD",
    location: "Bambino Gesù Children Hospital, Italy",
    project:
      "Improving care of PFIC patients in clinical practice: Focus on sleep disorders and their negative outcome on patient and caregiver quality of life (QoL)",
    desc: "To provide a detailed analysis of sleep disturbances in infants and children with PFIC. The goal is to inform treatment decisions and facilitate the incorporation of sleep assessment into routine clinical practice.",
  },
  {
    id: 2,
    image: NolwennLabordePhoto,
    name: "Dr Nolwenn Laborde",
    location: "Toulouse University Hospital, France",
    project: "Bright smile for Bright life",
    desc: "To assess the prevalence of dental problems in French patients with PFIC and other neonatal cholestasis, to evaluate how this impacts patients’ self-esteem and QoL. The goal is to increase disease awareness and advocate for funding of dental care for these patients.",
  },
  {
    id: 3,
    image: JoseWillemsePhoto,
    name: "José Willemse, MSc",
    location: "Dutch Liver Patients Association, Netherlands",
    project:
      "It’s going well, right? The hidden burden of parents with a child with liver disease",
    desc: "To develop an impact video that will help parents to vividly explain the challenges of having a child with chronic liver disease has on their everyday life.",
  },
  {
    id: 4,
    image: ToniIllhardtPhoto,
    name: "Dr Toni Illhardt",
    location: "University Children’s Hospital Tübingen, Germany",
    project:
      "Actigraphy – a tool to assess the degree of pruritus in paediatric cholestatic liver diseases",
    desc: "To assess whether actigraphy can be used effectively to quantify the impact of pruritus on sleep disruption and sleep quality in children with cholestatic liver diseases.",
  },
  {
    id: 5,
    image: AngeloDiGiorgioPhoto,
    name: "Dr Angelo di Giorgio",
    location: "Hospital Papa Giovanni XXIII, Italy",
    project:
      "Prognostic value of serum bile acid (SBA) levels on disease progression of paediatric patients with PFICs",
    desc: "To assess changes in SBA levels in patients with PFIC, and whether SBA could be a biomarker of disease progression.",
  },
  {
    id: 6,
    image: FilomenaMoriscoPhoto,
    name: "Prof. Filomena Morisco",
    location: "University of Naples “Frederico II”, Italy",
    project: "ICP: A first manifestation of unknown PFIC",
    desc: "To investigate the potential relationship between ICP and PFIC, including genetic mutations, prevalence and the associated risks of hepatobiliary diseases and cancers.",
  },
  {
    id: 7,
    image: GiovanniVitalePhoto,
    name: "Giovanni Vitale, MD",
    location: "University Hospital Bologna, Italy",
    project:
      "Optimizing the diagnostic workflow in adult patients with cryptogenic cholestatic liver diseases (CLD) through a genotype-phenotype screening approach using next-generation sequencing analysis",
    desc: "To improve PFIC/CLD/hepatobiliary cancer diagnosis, investigate genotype-phenotype relationships, and define key findings for prognosis and personalized therapies.",
  },
];
