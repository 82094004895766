import React from "react";
import {Link} from "react-router-dom";

const PrivacyPolicy: React.FC = () => <>
  <div className={"w-4/5 mx-auto bg-white text-black py-8 article-body"}>
    <h1 className={"text-3xl mb-8 font-bold"}>Privacy Policy</h1>

    <p className={"text-small text-gray-900 mb-6"}>Last updated November 11, 2021.</p>
    <p className={"mb-6"}>
      At Albireo Pharma, Inc. (“Albireo,” “we,” “us” or “our”) we take your privacy and the security of your information
      very seriously.
    </p>
    <p className={"mb-6"}>This Privacy Policy (“Policy”) covers this Albireo owned and operated website, other sites that
      link to this Policy (“Site” or “Sites”), and any services offered via the Sites, as well as through any of our
      offline services that reference this Policy (collectively, “Services”). This Policy is incorporated into, and is
      part of, our Terms of Use (<Link to={"/terms-of-use"} className={"underline text-albireo-blue"}>available
        here</Link>) which govern your access to the Site and your use of the Site and/or Services. Unless otherwise
      defined herein, capitalized terms shall have the meaning assigned to such terms in the Terms of Use.</p>
    <p className={"mb-6"}>If you have any questions regarding this Policy, please contact us by emailing <a
      href={"mailto:privacy@albireopharma.com"} className={"text-albireo-blue underline"}>privacy@albireopharma.com</a>.</p>
    <p className={"mb-6"}>The Policy describes the types of information we gather from people visiting our Site and from
      individual users (“you” or “users”) interacting with our Site and how we use, transfer, and secure such
      information. Your access to our Site and use of any Services indicates your acceptance of this Policy. This Policy
      does not govern information we receive from third parties. If you do not agree to the terms of this Policy, please
      do not use the Site, or any of our Services. Each time you use any Site, or any Services, the current version of
      this Privacy Policy will apply. Accordingly, when you use any Site or Services, you should check the date of this
      Policy (which appears at the top) and review any changes since you last reviewed the Policy.</p>
    <h2 className={"text-xl mb-6"}>Personal Information We Collect</h2>
    <h3 className={"text-l mb-4 font-bold"}>Data You Choose to Provide</h3>
    <p className={"mb-6"}>"Personal Information” is information that can be used to identify you (such as name and contact
      information). Personal Information that you may voluntarily provide through our Sites or otherwise share with us
      includes:</p>
    <ul className={"list-disc list-outside ml-6 mb-6"}>
      <li>Personal and Business Contact Information, such as your first name, last name, postal address, email address,
        telephone number, job title, and employer name;
      </li>
      <li>Professional Credentials, such as educational and work history, institutional affiliations and other
        information of the type that would be included on a resume or curriculum vitae;
      </li>
      <li>
        Profile Information, such as your username and password, industry, interests and references;
        Feedback and Correspondence, such as information you provide in your responses to surveys, when you participate
        in market research activities, report a problem with the Sites, receive customer support or otherwise correspond
        with us;
      </li>
      <li>Transaction Information, such as details about programs, events or other activities you register for through
        the Sites;
      </li>
      <li>Usage Information, such as information about how you use the Sites and interact with us;</li>
      <li>Discussion Board Interaction, such as information that you may choose to post to Albireo discussion boards or
        other online forums; and
      </li>
      <li>Marketing Information, such as your preferences for receiving marketing communications.</li>
    </ul>
    <h3 className={"text-l font-bold"}>Information Automatically Collected</h3>
    <p className={"mb-6"}>We may automatically log information about you and your computer or mobile device when you
      access our Sites. For example, we may log your computer or mobile device operating system name and version,
      manufacturer and model, browser type, browser language, screen resolution, the website you visited before browsing
      to our Sites, pages you viewed, how long you spent on a page, and access times and information about your use of,
      and actions on, our Sites. We collect this information about you using cookies. Please refer to the section below
      on cookies for more details.</p>
    <h3 className={"text-l font-bold"}>Use of “Cookies” and Similar Technologies</h3>
    <p className={"mb-6"}>Cookies are alphanumeric identifiers that are transferred to your computer’s hard drive through
      your web browser to help us identify you when you come to our Site. Our Site uses cookies to distinguish you from
      other users of our Site. This helps us to provide you with a better experience when you use our Site and allows us
      to improve our site.</p>
    <p className={"mb-6"}>You have choices with respect to cookies. By modifying your browser preferences, you have the
      choice to accept all cookies, to be notified when a cookie is set, or to reject all cookies. If you choose to
      reject all cookies you may be unable to use those aspects of our Sites that require registration to participate.
      You can learn about cookies and how they work at <a href={"http://www.allaboutcookies.org/"} target={"_blank"}
                                                          className={"text-albireo-blue underline"}>www.allaboutcookies.org</a>.
    </p>
    <p className={"mb-6"}>You can disable cookies through your browser settings. Doing so, however, may disable certain
      features on our Sites. You can opt out from third-party cookies that are used for advertising purposes on the NAI
      website at <a href={"http://www.networkadvertising.org/managing/opt_out.asp"} target={"_blank"}>www.networkadvertising.org/managing/opt_out.asp</a>. If you are accessing this site from the EU or UK, your
      consent to the placement of cookies indicates your consent to the transfer of data collected by such cookies to
      third countries that may not have a level of data protection comparable to the EU or UK. You can always withdraw
      your consent by adjusting your preferences. Depending on which Site you visit, we may use the following categories
      of cookies:</p>
    <p className={"mb-6"}><span className={"font-bold"}>Strictly Necessary Cookies</span>. These cookies are required for
      the operation of our Site. These cookies cannot be switched off. You can set your browser to block these cookies,
      but as a result, some parts of our Site will not work as designed.</p>
    <p className={"mb-6"}><span className={"font-bold"}>Analytics and Performance Cookies</span>. Analytical cookies allow
      us to analyze traffic to our Site and how our Site is used. For example, we use analytical cookies to count the
      number of visitors and sources of web traffic so we can see how users move around our Site. This helps us improve
      the way our Site works, for example, by ensuring that users can easily find what they are looking for. This
      “analytics data” is only available in aggregate and cannot be used to identify you.</p>
    <p className={"mb-6"}><span className={"font-bold"}>Functional Cookies</span>. These cookies allow our sites to remember
      choices you make when you use our sites. The purpose of these cookies is to provide you with a more personal
      experience and to avoid you from having to re-select your preferences every time you visit our sites.</p>
    <p className={"mb-6"}><span className={"font-bold"}>Targeting Cookies</span>. These cookies may be set through our site
      by our advertising partners. They may be used by those companies to build a profile of your interests and show you
      relevant advertisements on other sites. They do not store directly personal information but are based on uniquely
      identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted
      advertising.</p>
    <p className={"mb-6"}>We may also use cookies, pixels, beacons, or other web tracking technologies to track the amount
      of time spent on our Sites and whether certain content, such as a video was viewed. We may work with a trusted
      third party to collect and process this information for us, based on our instructions and in compliance with this
      Policy.</p>
    <p className={"mb-6"}>Although we do our best to honor the privacy preferences of our visitors, we are not able to
      respond to Do Not Track signals from your browser at this time.</p>
    <h2 className={"text-xl mb-6"}>How We Use Your Personal Information</h2>
    <h3 className={"text-l font-bold"}>To provide you with information and administer our Site</h3>
    <p className={"mb-6"}>If you use our Site or provide us with Personal Information to participate in our programs,
      events or other activities, we use your personal information to:</p>
    <ul className={"list-disc list-outside ml-6 mb-6"}>
      <li>operate, maintain, administer and improve the Site;</li>
      <li>process and manage registrations you make through the Site;</li>
      <li>provide information regarding our programs, events, or activities for which you may have registered;</li>
      <li>send you technical notices, updates, security alerts, and support and administrative messages;</li>
      <li>better understand your needs and interests, and personalize your experience with the Sites;</li>
      <li>provide support and maintenance for the Sites and our services; and</li>
      <li>respond to your service-related requests, questions and feedback.</li>
    </ul>
    <h3 className={"text-l font-bold"}>To communicate with you</h3>
    <p className={"mb-6"}>If you request information from us, we may send you commercial or marketing communications as
      permitted by applicable law. You may opt-out of such communications pursuant to the directions in each
      communication or contacting us at privacy@albireopharma.com.</p>
    <h3 className={"text-l font-bold"}>To comply with legal obligations</h3>
    <p className={"mb-6"}>We may use your Personal Information as necessary or appropriate to comply with applicable laws,
      lawful requests and legal processes, such as to respond to requests from government authorities.</p>
    <h2 className={"text-xl mb-6"}>With your consent</h2>
    <p className={"mb-6"}>We may use or share your Personal Information with your consent, such as to send you marketing
      communications, to post testimonials or images to our Site, or if you instruct us to take specific actions with
      regard to your Personal Information.</p>
    <h3 className={"text-l font-bold mb-4"}>For compliance, fraud prevention and safety</h3>
    <p className={"mb-6"}>We use your Personal Information as necessary or appropriate to (i) enforce the terms and
      conditions that govern use of our Sites; (ii) protect our rights, privacy, safety or property; and (iii) protect,
      investigate and deter fraudulent, harmful, unauthorized, unethical or illegal activity.</p>
    <h3 className={"text-l font-bold mb-4"}>For analytics purposes</h3>
    <p className={"mb-6"}>We may use third parties, such as Google Analytics or other analytics providers, to analyze
      traffic to a Site. Google Analytics does not create individual profiles for visitors and only collects aggregate
      data. To disable Google Analytics, download the browser add-on for the deactivation of Google Analytics provided
      by Google at <a href="http://tools.google.com/dlpage/gaoptout?hl=en" target={"_blank"}
                      className={"underline text-albireo-blue"}>http://tools.google.com/dlpage/gaoptout?hl=en</a>. To
      learn more about privacy and Google Analytics, consult the Google Analytics overview provided by Google at <a
        href={"http://www.google.com/intl/en/analytics/privacyoverview.html"} target={"_blank"}
        className={"underline text-albireo-blue"}>http://www.google.com/intl/en/analytics/privacyoverview.html</a>. You
      can find additional information about Google Analytics at <a
        href={"http://www.google.com/policies/privacy/partners/"} target={"_blank"}
        className={"underline text-albireo-blue"}>http://www.google.com/policies/privacy/partners/</a>.</p>
    <h2 className={"text-xl mb-6"}>Legal Bases for Processing</h2>
    <p className={"mb-6"}>If you reside in the United Kingdom, European Economic Area (“EEA”) or Switzerland, we are
      required to inform you of the legal bases of our processing of your Personal Information on our sites, which are
      provided below:</p>
    <table className={"rounded-lg border border-storm-cloud collapse mb-6"} cellPadding={"16px"}>
      <thead className={"bg-storm-cloud"}>
      <td className={"border border-storm-cloud-light"}>Processing purpose</td>
      <td className={"border border-storm-cloud-light"}>Legal basis</td>
      </thead>
      <tbody>
      <tr>
        <td className={"border border-storm-cloud-light"}>To provide services</td>
        <td className={"border border-storm-cloud-light"}>Processing is necessary to provide services to you or to take
          steps that you request prior to providing those services.
        </td>
      </tr>
      <tr>
        <td className={"border border-storm-cloud-light"}>To communicate with you
          <p className={"mb-6"}>For compliance, fraud prevention and safety purposes</p>
          <p className={"mb-6"}>To create anonymous analytics</p>
        </td>
        <td className={"border border-storm-cloud-light"}>These processing activities are based on our legitimate
          interests. We consider and balance potential impact on your rights and do not process your Personal
          Information for activities where our interests are overridden by the impact on you (unless we have your
          consent or are otherwise required or permitted to by law).
        </td>
      </tr>
      <tr>
        <td className={"border border-storm-cloud-light"}>
          To comply with law
        </td>
        <td className={"border border-storm-cloud-light"}>
          Processing is necessary to comply with our legal obligations.
        </td>
      </tr>
      <tr>
        <td className={"border border-storm-cloud-light"}>With your consent</td>
        <td className={"border border-storm-cloud-light"}>Processing is based on your consent. Where we rely on consent,
          you have the right to withdraw it at any time.
        </td>
      </tr>
      </tbody>
    </table>


    <h2 className={"text-xl mb-6"}>Sharing of Personal Information with Third Parties</h2>
    <p className={"mb-6"}>Albireo does not sell your Personal Information. We may transfer Personal Information to third
      parties for the purpose of providing Services. We may disclose Personal Information to our affiliates or
      third-party service providers to provide you with Services. These third-party service providers are not authorized
      to retain, share, store or use the Personal Information for any purposes other than to provide the services they
      have been hired to provide.</p>
    <p className={"mb-6"}>We may also elect to transfer your Personal Information to third parties under special
      circumstances, including: (i) to comply with a legal requirement, judicial proceeding, court order, or legal
      process served on us; (ii) to investigate a possible crime, such as fraud or identity theft; (iii) in connection
      with the sale, purchase, merger, reorganization, liquidation or dissolution of Albireo; (iv) when we believe it is
      necessary to protect the rights, property, or safety of Albireo or other persons, or (v) as otherwise required or
      permitted by law.</p>
    <p className={"mb-6"}>Some links on our Site may redirect you to third-party websites and services that we do not
      operate. The privacy practices of these websites and services will be governed by that website’s own policies. We
      make no representation or warranty as to the privacy policies of any third parties, including the providers of
      third-party applications. If you are submitting information to any such third party through our Site or Services,
      you should review and understand that party’s applicable policies, including their privacy policy, before
      providing your information to the third party.</p>
    <h2 className={"text-xl mb-6"}>Transfer of Information</h2>
    <p className={"mb-6"}>Albireo is based in the United States and has affiliates and service providers in other
      countries. Personal Information you provide to us or that is collected via our Sites may be transferred to the
      United States or other locations outside of your state, province or country where privacy laws may not be as
      protective as those in your country. We will process and transfer your Personal Information in accordance with
      applicable law and this Policy regardless of where your Personal Information is stored or accessed. Our
      third-party service providers are contractually bound to treat Personal Information in a manner that is consistent
      with this Notice and applicable data protection laws.</p>
    <h2 className={"text-xl mb-6"}>Children and Privacy</h2>
    <p className={"mb-6"}>We do not knowingly collect Personal Information from children in connection with the features
      of our Sites or Services. If we become aware that an individual under the age of 16 has provided Personal
      Information through our Sites or Services, we will immediately remove the individual’s Personal Information. We
      request that parents and guardians do not use the Site or email to provide us with any Personal Information
      concerning children.</p>
    <p className={"mb-6"}>Any communications relating to clinical trials should be made through the communication channels
      described in the applicable informed consent, patient information sheet or other instructions provided to clinical
      trial participants.</p>
    <h2 className={"text-xl mb-6"}>How We Protect Your Information</h2>
    <p className={"mb-6"}>We take the security of your Personal Information seriously. We use reasonable administrative,
      physical, and technical safeguards to secure the Personal Information you share with us. Despite these safeguards,
      we cannot guarantee the security of your Personal Information.</p>
    <p className={"mb-6"}>Any email or other communication purporting to be from one of our websites asking you to provide
      sensitive information (including medical information) via email, should be treated as unauthorized and suspicious
      and should be reported to us immediately by emailing <a href={"mailto:privacy@albireopharma.com"}
                                                              className={"underline text-albireo-blue"}>privacy@albireopharma.com</a>.
    </p>
    <h2 className={"text-xl mb-6"}>Retention</h2>
    <p className={"mb-6"}>We will only retain Personal Information for as long as is necessary to fulfill the purpose for
      which it was collected (or for any subsequent purpose that is compatible with the original purpose). This does not
      affect your right to request that we delete your Personal Information before the end of its retention period. We
      may archive Personal Information (which means storing it in inactive files) for a certain period prior to its
      final deletion, as part of our ordinary business continuity procedures.</p>
    <h2 className={"text-xl mb-6"}>Changes to This Privacy Policy</h2>
    <p className={"mb-6"}>Each time you use our Site or Services, the current version of the Policy will apply. When you
      use our Site, you should check the date of this Policy (which appears at the top of the Policy) and review any
      changes since the last version. Unless stated otherwise, our current Policy applies to all information that we
      have about you. We will not materially change our policies and practices to make them less protective of your
      privacy without the consent of affected users. When we make any change to this Policy that has a significant
      impact on the privacy rights of users, we will indicate such on the main page of the Site.</p>
    <h2 className={"text-xl mb-6"}>Your Rights</h2>
    <p className={"mb-6"}>If you reside in the United Kingdom, EEA or Switzerland, you may request that we take the
      following actions with regard to your Personal Information by contacting us at <a
        href={"mailto:privacy@albireopharma.com"} className={"underline text-albireo-blue"}>privacy@albireopharma.com</a>.
    </p>
    <ul className={"list-disc list-outside ml-6 mb-6"}>
      <li>Access: Provide you with information about our processing of your Personal Information and give you access to
        your Personal Information.
      </li>
      <li>Correction: Update or correct inaccurate Personal Information.</li>
      <li>Deletion: Delete your Personal Information.</li>
      <li>Transfer: Transfer a machine-readable copy of your Personal Information to you or a third party of your
        choice.
      </li>
      <li>Restriction: Restrict the processing of your Personal Information.</li>
      <li>Objection: Object to our legitimate interest as the basis of our processing of your Personal Information.</li>
    </ul>
    <p className={"mb-6"}>If you reside in California, you or your authorized agent may request that we take the following
      actions with regard to your Personal Information by contacting us at <a href={"mailto:privacy@albireopharma.com"}
                                                                              className={"underline text-albireo-blue"}>privacy@albireopharma.com</a>
    </p>
    <ul className={"list-disc list-outside ml-6 mb-6"}>
      <li>Access: Disclose to you the categories of Personal Information we have collected, used or disclosed within the
        last 12 months, including Personal Information, if any, that we disclosed to third parties for marketing
        purposes.
      </li>
      <li>Deletion: Delete your Personal Information.</li>
      <li>Transfer: Transfer a copy of specific pieces of your Personal Information that we have collected or used
        within the last 12 months.
      </li>
    </ul>
    <p className={"mb-6"}>We may require additional information to verify and process your request. Applicable law may
      require or permit us to decline your request. If we decline your request, we will inform you of such decision,
      subject to legal restrictions.</p>
    <p className={"mb-6"}>If you no longer wish to receive promotional communications, you may opt out of receiving them
      by following the instructions included in each communication.</p>
    <p className={"mb-6"}>If you are a resident of the United Kingdom, EEA or Switzerland, you have the right to file a
      complaint concerning our processing of your Personal Information with your national (or in some countries,
      regional) data protection authority.</p>
    <h2 className={"text-xl mb-6"}>Contact Us</h2>
    <p className={"mb-6"}>To contact us with questions or comments regarding this Policy or the information collection and
      dissemination practices of this Site, or to request this Policy in an alternative format due to a disability,
      please email us at <a href={"mailto:privacy@albireopharma.com"}
                            className={"underline text-albireo-blue"}>privacy@albireopharma.com</a>, or write to us at:
    </p>
    <p>Albireo Pharma, Inc.</p>
    <p>Legal and Compliance Department</p>
    <p>53 State Street</p>
    <p>19th Floor</p>
    <p className={"mb-6"}>Boston, MA 02109</p>
    <p className={"mb-6"}>If you reside in the United Kingdom, EEA or Switzerland and you seek to exercise any of your
      statutory rights, you may also contact our Data Protection Officer by sending an email <a
        href={"mailto:privacy@albireopharma.com"}
        className={"underline text-albireo-blue"}>privacy@albireopharma.com</a> with the subject line “Data Protection
      Officer”.</p>
  </div>
</>

export default PrivacyPolicy
