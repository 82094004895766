import React, { PropsWithChildren } from "react";
import logo from "../../views/img/logo.png";
import sparkp from "../../views/img/SPARK_Programme.png";
import "../../css/index.css";
import { Link } from "react-router-dom";

const FormPage: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <header className="mt-0 sm:bg-[url('../views/img/curve.jpg')] sm:bg-no-repeat sm:bg-contain sm:bg-left-top mb-4">
        <a href="/">
          <div className="grid w-4/5 grid-cols-2 gap-8 py-2 mx-auto">
            <div className="flex flex-col items-end place-self-end sm:flex-none md:place-self-start sm:items-start">
              <img className="w-auto md:h-16" src={logo} alt="Albireo logo" />
              <div className="font-sans text-base font-bold text-blue-900 sm:text-lg md:text-xl">
                <span className="text-flame">Grants in Rare Liver Disease</span>
              </div>
            </div>
            <div className="inline-flex flex-col items-end my-4 mt-4 mb-0 md:mt-0 place-self-end">
              <img className="w-auto md:h-32" src={sparkp} alt="spark logo" />
            </div>
          </div>
        </a>
      </header>

      <div className={"flex flex-col"}>
        <div
          className={
            "py-8 w-full text-white bg-gradient-to-br from-midnight to-albireo-blue"
          }
        >
          <div className={"w-4/5 mx-auto text-3xl text-center"}>
            <h2 className={"text-3xl font-bold"}>
              <span className={"text-flame"}>SPARK</span> is an exciting program
              established by Albireo
            </h2>
            <p className={"text-2xl"}>
              to identify and drive forward improvements in the quality of care
              for rare liver diseases.
            </p>
          </div>
        </div>
        <div
          className={
            "py-4 w-full bg-white border-t border-b border-flame text-flame"
          }
        >
          <div className={"w-4/5 mx-auto p-0"}>
            <h2 className={"text-xl font-bold text-center"}>
              The program will cover both progressive familial intrahepatic
              cholestasis (PFIC) and Alagille syndrome (ALGS) in 2023.
            </h2>
          </div>
        </div>
      </div>

      {children}

      <div className="w-full py-8 text-white bg-gradient-to-br from-midnight-dark to-midnight">
        <div className="w-4/5 mx-auto text-center">
          <div className="grid grid-cols-4">
            <div className={"text-white"}>
              <Link to={"/"} className={"underline"}>
                About SPARK and How to Apply
              </Link>
            </div>
            <div className={"text-white"}>
              <Link to={"/awardees"} className={"underline"}>
                2022 Grant Awardees
              </Link>
            </div>
            <div className={"text-white"}>
              <Link to={"/privacy-policy"} className={"underline"}>
                Privacy Policy
              </Link>
            </div>
            <div className={"text-white"}>
              <Link to={"/terms-of-use"} className={"underline"}>
                Terms of Use
              </Link>
            </div>
            {/*<div className={"text-white"}>Cookies Preferences</div>*/}
          </div>
        </div>
      </div>
      <div className="w-full py-8">
        <div className="w-4/5 p-0 mx-auto">
          <p className="text-sm text-right text-gray-500">
            &copy; 2023 Albireo Pharma, Inc.&nbsp;|&nbsp;Job bag no:
            GL-NP-23-00003 &nbsp;|&nbsp;Date of preparation: May 2023
          </p>
        </div>
      </div>
    </>
  );
};

export default FormPage;
