import React from "react";
import { useNavigate } from "react-router";
import sbab from "../img/stripey-baby-800.jpg";
import { Link } from "react-router-dom";

const Home: React.FC = () => {
  const nav = useNavigate();
  return (
    <>
      <div className={"bg-white text-black py-8"}>
        <div className={"px-8 md:px-0 md:w-4/5 mx-auto p-0"}>
          <div className={"md:flex md:flex-row md:gap-x-8"}>
            <div className={"md:flex-auto md:w-2/3"}>
              <h2
                className={"font-medium font-serif text-midnight text-5xl mb-4"}
              >
                About the SPARK grants program
              </h2>
              <h2 className={"mt-8 mb-4 text-xl font-bold"}>
                SPARK is designed to encourage new ideas for best practices in
                patient-centered care within rare liver diseases.
              </h2>
              <h2 className={"mt-8 mb-4 text-xl font-bold"}>
                Applications should cover sustainable, innovative, replicable
                projects, which improve the standard of care of PFIC and ALGS
                and optimize the care pathway, for example, by:
              </h2>
              <ul className={"text-xl ml-6"}>
                <li className={"list-disc"}>
                  Measurably improving the time to diagnosis
                  <ul className="mb-2 ml-6">
                    <li className={"list-circle"}>
                      e.g. identifying rare PFIC sub-types; adult onset PFIC
                      including episodic or idiopathic presentations
                    </li>
                  </ul>
                </li>
                <li className={"list-disc mb-2"}>
                  {" "}
                  Measurably reducing the time between diagnosis and the
                  initiation of appropriate, high-quality care
                </li>
                <li className={"list-disc mb-2"}>
                  {" "}
                  Measurably improving the standard of care of patients
                  <ul className="mb-2 ml-6">
                    <li className={"list-circle"}>
                      e.g. improving cross-functional working; continuity of
                      care; serving remote populations
                    </li>
                  </ul>
                </li>
              </ul>
              <p className={"text-xl mt-4 font-bold"}>
                Examples include but are not limited to: initiatives to support
                case finding, audit, screening, education and awareness.
              </p>
              <div className={"text-flame font-bold text-xl mt-6 mb-2"}>
                <Link to={"/awardees"} className={"underline"}>
                  Please click here to view the projects that were awarded SPARK
                  funding in 2022.
                </Link>
              </div>

              <div className={"bg-white mt-8"}>
                <button
                  onClick={() => {
                    nav("/apply");
                  }}
                  className={
                    "w-full md:w-auto border-b-2 border-orange-900 text-2xl font-bold text-white rounded-md bg-flame px-4 py-2"
                  }
                >
                  Apply for a grant &rarr;
                </button>
              </div>
              <div className={"rounded-md bg-storm-cloud-light p-8 mt-8"}>
                <h2 className={"text-2xl font-bold text-midnight mb-4"}>
                  The application process
                </h2>
                <ul className={"text-xl ml-6"}>
                  <li className={"list-disc"}>
                    The deadline for 2023 grant applications is Sunday 15th October.
                  </li>
                  <li className={"list-disc"}>
                    All applicants will be informed of the outcome of their applications in December 2023, with funding to start in early 2024.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                "mt-8 md:mt-0 md:flex-auto md:w-1/3 md:border-l-2 md:pl-8 border-storm-cloud"
              }
            >
              <h2 className={"text-2xl font-bold text-albireo-blue"}>
                About Albireo
              </h2>
              <p className={"text-lg mt-4"}>
                Albireo, an Ipsen Company, is a global pharmaceutical company
                focused on the development of novel bile acid modulators to
                treat rare pediatric and adult liver diseases. As a leader in
                bile acid biology, we leverage our expertise in bile acid
                modulation with the goal of providing hope for families by
                delivering potentially transformative medicines.
              </p>
              <p className={"text-lg mt-4"}>
                <a
                  className={"underline text-albireo-blue"}
                  href={"https://albireopharma.com/"}
                  target={"_blank"}
                >
                  Visit the Albireo website
                </a>
              </p>
              <img src={sbab} alt={"Stripy baby"} className={"mt-8"} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
