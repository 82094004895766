import React, {useState} from "react";
import UploadButton from "./UploadButton";
import {apiHost} from "../../../service/api-host";
import axios from "axios";

interface IUploadForm {
  onComplete(): void
}

const UploadForm: React.FC<IUploadForm> = ({onComplete}) => {

  const [files, setFiles] = useState<Record<string, any>>({})
  const [key, setKey] = useState("abc123")
  const [message, setMessage] = useState<string | React.ReactFragment | React.ReactElement | undefined>()
  const [status, setStatus] = useState<"success" | "error" | undefined>()

  const updateFiles = (file: any, name: string) => {
    const fs = {...files, [name]: file}
    setFiles(fs)
  }

  const upload = async (): Promise<void> => {
    setMessage(undefined)
    setStatus(undefined)
    if(Object.keys(files).length < 3) {
      setMessage("Please select files to upload")
      setStatus("error")
      return
    }

    const formData = new FormData()
    Object.keys(files).forEach((key) => {
      formData.append(key, files[key], files[key].name)
    })


    try {
      await axios.post(`${apiHost()}/api/apply`, formData)
      setMessage("Upload Completed")
      setStatus("success")
    } catch (e: any) {
      setMessage(<>Upload Failed. Please <a className={"text-albireo-blue underline"} href={"mailto:help@albireopharma.com"}>contact us</a>.</>)
      setStatus("error")
    }

    setKey(Math.random().toString().substring(0, 3))
    setFiles({})

  }

  return <>
    <div className={"p-2 md:p-4 border border-albireo-blue mb-8"}>
    <UploadButton title={"File One"} name={"file-one"} onChange={updateFiles} key={`${key}first`}/>
    <UploadButton title={"File Two"} name={"file-two"} onChange={updateFiles} key={`${key}second`}/>
    <UploadButton title={"File Three"} name={"file-three"} onChange={updateFiles} key={`${key}third`}/>

    <button
      onClick={(e) => {
        upload()
      }}
      className={"mt-4 w-full md:w-auto border-b-4 border-crimson text-2xl font-bold text-white rounded-md bg-flame-900 px-4 py-2"}>
      Submit your application
      <i className={"fa-solid fa-angle-right ml-4"}/>
    </button>
      {message && <p className={`mt-4${status?` ${status}`:""}`}>{message}</p>}
    </div>
  </>
}

export default UploadForm
